<template>
  <div>
    <h2>Composition of an Alloy: Known Metal Calculations</h2>
    <v-form>
      <!-- Data Table -->
      <h3 class="mb-4 mt-8">Data Table</h3>
      <v-textarea
        v-model="inputs.data_table_caption"
        label="Table Caption"
        outlined
        rows="1"
        class="mb-n3"
        placeholder="Write a caption for the data table here."
      />
      <v-simple-table>
        <thead>
          <tr>
            <td colspan="2">&nbsp;</td>
            <td colspan="2" style="font-weight: bold; text-align: center">Known Metal</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td style="font-weight: bold; text-align: center">Units</td>
            <td style="font-weight: bold; text-align: center">Trial 1</td>
            <td style="font-weight: bold; text-align: center">Trial 2</td>
          </tr>
        </thead>
        <tr>
          <td style="font-weight: bold">Identity</td>
          <td>&nbsp;</td>
          <td v-for="i in 2" :key="`known-${i}`">
            <v-select
              v-model="inputs[`trial${i}_identity`]"
              :items="['Zn', 'Mg']"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item" />
              </template>
            </v-select>
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Mass</td>
          <td>
            <v-select
              v-model="inputs['mass_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input v-model="inputs[`trial${i}_mass`]" class="centered-input" />
          </td>
        </tr>
        <tr>
          <td><stemble-latex content="$p_i$" /></td>
          <td>
            <v-select
              v-model="inputs['initialPressure_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input
              v-model="inputs[`trial${i}_initialPressure`]"
              class="centered-input"
            />
          </td>
        </tr>
        <tr>
          <td><stemble-latex content="$p_f$" /></td>
          <td>
            <v-select
              v-model="inputs['finalPressure_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input v-model="inputs[`trial${i}_finalPressure`]" class="centered-input" />
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Room Temperature</td>
          <td>
            <v-select
              v-model="inputs['roomTemperature_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input
              v-model="inputs[`trial${i}_roomTemperature`]"
              class="centered-input"
            />
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Mass of Empty Flask</td>
          <td>
            <v-select
              v-model="inputs['massEmptyFlask_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input
              v-model="inputs[`trial${i}_massEmptyFlask`]"
              class="centered-input"
            />
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Mass of Filled Flask</td>
          <td>
            <v-select
              v-model="inputs['massFilledFlask_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>

          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input
              v-model="inputs[`trial${i}_massFilledFlask`]"
              class="centered-input"
            />
          </td>
        </tr>
      </v-simple-table>

      <!-- Known Metal Calculations Table -->
      <h3 class="mb-4 mt-8">Calculations: Known Metal</h3>

      <v-textarea
        v-model="inputs.calculations_table_caption"
        label="Table Caption"
        outlined
        rows="1"
        class="mb-n3"
        placeholder="Write a caption for the known metal table here."
      />
      <v-simple-table>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td style="text-align: center; font-weight: bold">Units</td>
            <td style="font-weight: bold; text-align: center">Trial 1</td>
            <td style="font-weight: bold; text-align: center">Trial 2</td>
          </tr>
        </thead>
        <tr>
          <td style="font-weight: bold">Amount of Metal</td>
          <td>
            <v-select
              v-model="inputs['molesOfMetal_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input v-model="inputs[`trial${i}_molesOfMetal`]" class="centered-input" />
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Amount of Hydrogen (Theoretical)</td>
          <td>
            <v-select
              v-model="inputs['molesOfHydrogenGasExpected_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input
              v-model="inputs[`trial${i}_molesOfHydrogenGasExpected`]"
              class="centered-input"
            />
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Volume of Flask</td>
          <td>
            <v-select
              v-model="inputs['volumeOfFlask_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input v-model="inputs[`trial${i}_volumeOfFlask`]" class="centered-input" />
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Volume of Flask Available</td>
          <td>
            <v-select
              v-model="inputs['volumeAvailable_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input
              v-model="inputs[`trial${i}_volumeAvailable`]"
              class="centered-input"
            />
          </td>
        </tr>
        <tr>
          <td><stemble-latex content="$\Delta p$" /></td>
          <td>
            <v-select
              v-model="inputs['changeInPressure_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input
              v-model="inputs[`trial${i}_changeInPressure`]"
              class="centered-input"
            />
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Amount of Hydrogen (Experimental)</td>
          <td>
            <v-select
              v-model="inputs['molesOfHydrogenGasActual_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input
              v-model="inputs[`trial${i}_molesOfHydrogenGasActual`]"
              class="centered-input"
            />
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">Percent Yield</td>
          <td>
            <v-select
              v-model="inputs['percentYield_units']"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td v-for="i in 2" :key="`known-${i}`">
            <calculation-input v-model="inputs[`trial${i}_percentYield`]" class="centered-input" />
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'OttawaLab1ReportTask2Data',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        data_table_caption: null,
        constants_table_caption: null,
        calculations_table_caption: null,
        mass_units: null,
        initialPressure_units: null,
        finalPressure_units: null,
        roomTemperature_units: null,
        massEmptyFlask_units: null,
        massFilledFlask_units: null,

        molesOfMetal_units: null,
        molesOfHydrogenGasExpected_units: null,
        volumeOfFlask_units: null,
        volumeAvailable_units: null,
        changeInPressure_units: null,
        molesOfHydrogenGasActual_units: null,
        percentYield_units: null,

        trial1_identity: null,
        trial1_mass: null,
        trial1_initialPressure: null,
        trial1_finalPressure: null,
        trial1_roomTemperature: null,
        trial1_massEmptyFlask: null,
        trial1_massFilledFlask: null,
        trial2_identity: null,
        trial2_mass: null,
        trial2_initialPressure: null,
        trial2_finalPressure: null,
        trial2_roomTemperature: null,
        trial2_massEmptyFlask: null,
        trial2_massFilledFlask: null,

        trial1_molesOfMetal: null,
        trial1_molesOfHydrogenGasExpected: null,
        trial1_volumeOfFlask: null,
        trial1_volumeAvailable: null,
        trial1_changeInPressure: null,
        trial1_molesOfHydrogenGasActual: null,
        trial1_percentYield: null,
        trial2_molesOfMetal: null,
        trial2_molesOfHydrogenGasExpected: null,
        trial2_volumeOfFlask: null,
        trial2_volumeAvailable: null,
        trial2_changeInPressure: null,
        trial2_molesOfHydrogenGasActual: null,
        trial2_percentYield: null,
      },
      constants: [
        {key: 'molarMassZn', label: 'Molar Mass of Zinc'},
        {key: 'molarMassMg', label: 'Molar Mass of Magnesium'},
        {key: 'molarMassAl', label: 'Molar Mass of Aluminum'},
        {key: 'densityOfWater', label: 'Density of Water'},
        {key: 'idealGasConstant', label: 'Ideal Gas Constant'},
      ],
      units: [
        {key: 'g', label: '$\\text{g}$'},
        {key: 'kPa', label: '$\\text{kPa}$'},
        {key: 'mL', label: '$\\text{mL}$'},
        {key: 'gPerMol', label: '$\\text{g} \\cdot \\text{mol}^{-1}$'},
        {key: 'degreesCelsius', label: '$^\\omicron\\text{C}$'},
        {key: 'gramsPerMillilitre', label: '$\\text{g} \\cdot \\text{mL}^{-1}$'},
        {
          key: 'joulesPerKelvinPerMole',
          label: '$\\text{J} \\cdot \\text{K}^{-1} \\cdot \\text{mol}^{-1}$',
        },
        {
          key: 'moles',
          label: '$\\text{mol}$',
        },
        {
          key: 'percent',
          label: '$\\text{\\%}$',
        },
      ],
    };
  },
};
</script>
