var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Composition of an Alloy: Known Metal Calculations")]),_c('v-form',[_c('h3',{staticClass:"mb-4 mt-8"},[_vm._v("Data Table")]),_c('v-textarea',{staticClass:"mb-n3",attrs:{"label":"Table Caption","outlined":"","rows":"1","placeholder":"Write a caption for the data table here."},model:{value:(_vm.inputs.data_table_caption),callback:function ($$v) {_vm.$set(_vm.inputs, "data_table_caption", $$v)},expression:"inputs.data_table_caption"}}),_c('v-simple-table',[_c('thead',[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v(" ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"},attrs:{"colspan":"2"}},[_vm._v("Known Metal")])]),_c('tr',[_c('td',[_vm._v(" ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("Units")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("Trial 1")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("Trial 2")])])]),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Identity")]),_c('td',[_vm._v(" ")]),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('v-select',{attrs:{"items":['Zn', 'Mg'],"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}],null,true),model:{value:(_vm.inputs[("trial" + i + "_identity")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_identity"), $$v)},expression:"inputs[`trial${i}_identity`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Mass")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['mass_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'mass_units', $$v)},expression:"inputs['mass_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_mass")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_mass"), $$v)},expression:"inputs[`trial${i}_mass`]"}})],1)})],2),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$p_i$"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['initialPressure_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'initialPressure_units', $$v)},expression:"inputs['initialPressure_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_initialPressure")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_initialPressure"), $$v)},expression:"inputs[`trial${i}_initialPressure`]"}})],1)})],2),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$p_f$"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['finalPressure_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'finalPressure_units', $$v)},expression:"inputs['finalPressure_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_finalPressure")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_finalPressure"), $$v)},expression:"inputs[`trial${i}_finalPressure`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Room Temperature")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['roomTemperature_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'roomTemperature_units', $$v)},expression:"inputs['roomTemperature_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_roomTemperature")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_roomTemperature"), $$v)},expression:"inputs[`trial${i}_roomTemperature`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Mass of Empty Flask")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['massEmptyFlask_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'massEmptyFlask_units', $$v)},expression:"inputs['massEmptyFlask_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_massEmptyFlask")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_massEmptyFlask"), $$v)},expression:"inputs[`trial${i}_massEmptyFlask`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Mass of Filled Flask")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['massFilledFlask_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'massFilledFlask_units', $$v)},expression:"inputs['massFilledFlask_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_massFilledFlask")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_massFilledFlask"), $$v)},expression:"inputs[`trial${i}_massFilledFlask`]"}})],1)})],2)]),_c('h3',{staticClass:"mb-4 mt-8"},[_vm._v("Calculations: Known Metal")]),_c('v-textarea',{staticClass:"mb-n3",attrs:{"label":"Table Caption","outlined":"","rows":"1","placeholder":"Write a caption for the known metal table here."},model:{value:(_vm.inputs.calculations_table_caption),callback:function ($$v) {_vm.$set(_vm.inputs, "calculations_table_caption", $$v)},expression:"inputs.calculations_table_caption"}}),_c('v-simple-table',[_c('thead',[_c('tr',[_c('td',[_vm._v(" ")]),_c('td',{staticStyle:{"text-align":"center","font-weight":"bold"}},[_vm._v("Units")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("Trial 1")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("Trial 2")])])]),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Amount of Metal")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['molesOfMetal_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'molesOfMetal_units', $$v)},expression:"inputs['molesOfMetal_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_molesOfMetal")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_molesOfMetal"), $$v)},expression:"inputs[`trial${i}_molesOfMetal`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Amount of Hydrogen (Theoretical)")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['molesOfHydrogenGasExpected_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'molesOfHydrogenGasExpected_units', $$v)},expression:"inputs['molesOfHydrogenGasExpected_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_molesOfHydrogenGasExpected")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_molesOfHydrogenGasExpected"), $$v)},expression:"inputs[`trial${i}_molesOfHydrogenGasExpected`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Volume of Flask")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['volumeOfFlask_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'volumeOfFlask_units', $$v)},expression:"inputs['volumeOfFlask_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_volumeOfFlask")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_volumeOfFlask"), $$v)},expression:"inputs[`trial${i}_volumeOfFlask`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Volume of Flask Available")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['volumeAvailable_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'volumeAvailable_units', $$v)},expression:"inputs['volumeAvailable_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_volumeAvailable")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_volumeAvailable"), $$v)},expression:"inputs[`trial${i}_volumeAvailable`]"}})],1)})],2),_c('tr',[_c('td',[_c('stemble-latex',{attrs:{"content":"$\\Delta p$"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['changeInPressure_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'changeInPressure_units', $$v)},expression:"inputs['changeInPressure_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_changeInPressure")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_changeInPressure"), $$v)},expression:"inputs[`trial${i}_changeInPressure`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Amount of Hydrogen (Experimental)")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['molesOfHydrogenGasActual_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'molesOfHydrogenGasActual_units', $$v)},expression:"inputs['molesOfHydrogenGasActual_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_molesOfHydrogenGasActual")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_molesOfHydrogenGasActual"), $$v)},expression:"inputs[`trial${i}_molesOfHydrogenGasActual`]"}})],1)})],2),_c('tr',[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v("Percent Yield")]),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}]),model:{value:(_vm.inputs['percentYield_units']),callback:function ($$v) {_vm.$set(_vm.inputs, 'percentYield_units', $$v)},expression:"inputs['percentYield_units']"}})],1),_vm._l((2),function(i){return _c('td',{key:("known-" + i)},[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("trial" + i + "_percentYield")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("trial" + i + "_percentYield"), $$v)},expression:"inputs[`trial${i}_percentYield`]"}})],1)})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }